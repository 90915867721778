import React from 'react';

import { ALREADY_VISITED_STORAGE_KEY } from '../../utils/constants';
import * as localStorageUtil from '../../utils/local-storage';

import * as tracker from './TopBar.tracker';

import { useHeaderStore } from '../../Header.store';

interface UseLocationPopoverProps {
  toggleModal: () => void;
}

const IS_FIRST_VISIT = !localStorageUtil.getValue(
  ALREADY_VISITED_STORAGE_KEY,
  true,
);

export function useLocationPopover(props: UseLocationPopoverProps) {
  const { toggleModal } = props;

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const { locationSource, regionName } = useHeaderStore((state) => ({
    locationSource: state.locationContext.locationSource,
    regionName: state.locationContext.regionName,
  }));

  const IS_AUTOMATIC_LOCATION = locationSource === 'cloudflare';

  const analytics = React.useMemo(
    () => ({
      regionName,
      isAutomaticShow: IS_AUTOMATIC_LOCATION && IS_FIRST_VISIT,
      locationSource,
    }),
    [IS_AUTOMATIC_LOCATION, locationSource, regionName],
  );

  const onLocationPopperChangeClick = React.useCallback(() => {
    toggleModal();

    tracker.popover({
      ...analytics,
      type: 'change',
    });
  }, [toggleModal, analytics]);

  const onLocationPopperConfirmClick = React.useCallback(() => {
    tracker.popover({
      ...analytics,
      type: 'confirm',
    });
  }, [analytics]);

  React.useEffect(() => {
    if (IS_AUTOMATIC_LOCATION && IS_FIRST_VISIT) {
      setIsPopoverOpen(true);
    }

    localStorageUtil.setValue(ALREADY_VISITED_STORAGE_KEY, 'true');
  }, [IS_AUTOMATIC_LOCATION]);

  React.useEffect(() => {
    isPopoverOpen && tracker.popover({ ...analytics, type: 'show' });
  }, [isPopoverOpen, analytics]);

  return {
    isPopoverOpen,
    onPopoverOpenChange: setIsPopoverOpen,
    onLocationPopperChangeClick,
    onLocationPopperConfirmClick,
  };
}
