import React from 'react';
import type { BaseSyntheticEvent, MouseEvent, KeyboardEvent } from 'react';

import {
  TextField,
  IconButton,
  Stack,
  CloseButton,
  Hidden,
} from '@backyard-ui/core';
import { ChevronLeft } from '@backyard-ui/icons';
import { useMediaQuery } from '@backyard-ui/hooks';

import { createAutocomplete } from '@algolia/autocomplete-core';
import type { AutocompleteState } from '@algolia/autocomplete-core';
import {
  getAlgoliaResults,
  RequestParams,
} from '@algolia/autocomplete-preset-algolia';
import { useSearchBox } from 'react-instantsearch';

import QuerySuggestions from './Results/QuerySuggestions';
import ProductList from './Results/ProductList';
import RecentsSearch from './Results/RecentsSearch';

import { plugins } from './Searchbar.plugins';
import type { PluginsOptions } from './Searchbar.plugins';

import { sendGTMEvent } from '../../../utils/sendGTMEvent';
import { useSearchParams } from '../../../hooks/use-search-params';
import { useHeaderStore } from '../../../Header.store';
import type { AutocompleteItem } from './Searchbar.types';

import styles from './Searchbar.styles';

export interface SearchbarProps {
  algolia: PluginsOptions & RequestParams<AutocompleteItem>;
}

function Searchbar(props: SearchbarProps) {
  const { algolia } = props;

  const { routing, region } = useHeaderStore((state) => ({
    routing: state.routing,
    region: state.locationContext.region,
  }));
  const { refine } = useSearchBox();
  const query = useSearchParams();

  const [autocompleteState, setAutocompleteState] = React.useState<
    AutocompleteState<AutocompleteItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  });
  const isDesktop = useMediaQuery('lg');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef(null);
  const panelRef = React.useRef(null);

  const { recents, suggestions, insights, redirect } = React.useMemo(() => {
    return plugins({
      indexName: algolia.indexName,
      insightsClient: algolia.insightsClient,
      searchClient: algolia.searchClient,
    });
  }, [algolia.indexName, algolia.insightsClient, algolia.searchClient]);

  const autocomplete = React.useMemo(() => {
    return createAutocomplete<
      AutocompleteItem,
      BaseSyntheticEvent,
      MouseEvent,
      KeyboardEvent
    >({
      placeholder: 'O que você procura na Leroy Merlin?',
      insights: true,
      openOnFocus: true,
      plugins: region ? [recents, suggestions, insights, redirect] : [],
      initialState: {
        query: decodeURIComponent(query.term || ''),
      },
      onReset() {
        setAutocompleteState((prev) => ({
          ...prev,
          query: '',
        }));
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setAutocompleteState((prev) => ({
            ...prev,
            query: state.query,
            isOpen: state.isOpen && !!region,
          }));
        } else {
          setAutocompleteState({
            ...state,
            isOpen: state.isOpen && !!region,
          });
        }
      },
      onSubmit({ state }) {
        if (!state.query) {
          return;
        }

        sendGTMEvent({
          value: {
            search: {
              term: state.query,
              type: 'default',
            },
          },
        });

        if (routing) {
          window.location.href = `/search?term=${state.query}&searchTerm=${state.query}&searchType=default`;
        } else {
          refine(state.query);
        }
      },
      ...(region && {
        getSources: () => {
          return [
            {
              sourceId: 'products',
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient: algolia.searchClient,
                  queries: algolia.queries.map((q) => ({
                    ...q,
                    query,
                    indexName: algolia.indexName,
                    params: {
                      ...q.params,
                      hitsPerPage: q.params?.hitsPerPage ?? 4,
                      clickAnalytics: q.params?.clickAnalytics ?? true,
                      filters:
                        q.params?.filters ??
                        `regionalAttributes.${region}.promotionalPrice>0 AND regionalAttributes.${region}.available=1`,
                    },
                  })),
                });
              },
              onSelect({ state }) {
                sendGTMEvent({
                  value: {
                    search: {
                      term:
                        state.query || inputRef?.current?.value || 'sem-termo',
                      type: 'recommendedProduct',
                    },
                  },
                });
              },
            },
          ];
        },
      }),
    });
  }, [
    recents,
    suggestions,
    insights,
    redirect,
    routing,
    query,
    refine,
    algolia,
    region,
  ]);

  React.useEffect(() => {
    if (!(formRef.current && panelRef.current && inputRef.current)) {
      return;
    }

    const { onTouchStart, onTouchMove, onMouseDown } =
      autocomplete.getEnvironmentProps({
        formElement: formRef.current,
        panelElement: panelRef.current,
        inputElement: inputRef.current,
      });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, [autocomplete, autocompleteState.isOpen]);

  const AUTOCOMPLETE_ID = '@autocomplete-quicksearch-input';
  const AUTOCOMPLETE_LABEL = '@autocomplete-quicksearch-label';

  const autocompleteProps = {
    root: autocomplete.getRootProps(),
    form: autocomplete.getFormProps({
      inputElement: inputRef.current,
    }),
    input: autocomplete.getInputProps({
      inputElement: inputRef.current,
      id: AUTOCOMPLETE_ID,
      // eslint-disable-next-line no-useless-computed-key
      ['aria-labelledby']: null,
    }),
    panel: autocomplete.getPanelProps(),
  };

  const hasRecentSearchQuery = Boolean(
    recents.data?.getAlgoliaSearchParams().facetFilters?.length,
  );

  return (
    <div
      className={styles({
        isOpen: autocompleteState.isOpen,
      }).base()}
      {...autocompleteProps.root}
    >
      <form
        className={styles().form()}
        ref={formRef}
        {...autocompleteProps.form}
      >
        <TextField.Root>
          {!isDesktop && autocompleteState.isOpen && (
            <TextField.Slot>
              <IconButton
                type="button"
                ariaLabel="Fechar busca"
                size="xs"
                appearance="neutral"
                variant="ghost"
                icon={<ChevronLeft />}
                onClick={() => {
                  setAutocompleteState((prev) => ({
                    ...prev,
                    isOpen: false,
                  }));
                }}
              />
            </TextField.Slot>
          )}
          <TextField.Input
            UNSAFE_className={styles().input()}
            ref={inputRef}
            {...autocompleteProps.input}
          />
          <TextField.Slot>
            <Stack align="center" spacing={1}>
              {autocompleteState.query && (
                <Hidden above="md">
                  <CloseButton
                    type="reset"
                    ariaLabel="Limpar campo de busca"
                    size="xs"
                    onClick={() => {
                      autocomplete.setQuery('');
                      inputRef.current?.focus();
                    }}
                  />
                </Hidden>
              )}

              <IconButton
                appearance="neutral"
                variant="ghost"
                size="xs"
                ariaLabel="Buscar"
                type="submit"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 23 25"
                  >
                    <path
                      fill="currentColor"
                      d="m22.16 20.679-5.023-5.024a9.77 9.77 0 0 0 1.786-5.581c.112-5.135-4.13-9.377-9.376-9.377a9.48 9.48 0 0 0-9.489 9.489 9.48 9.48 0 0 0 9.489 9.488c1.34 0 2.79-.335 4.018-.893l5.247 5.247c.446.446 1.004.67 1.674.67.558 0 1.228-.224 1.674-.67l.112-.112c.447-.447.67-1.005.67-1.563-.112-.67-.335-1.228-.782-1.674Zm-5.916-10.493c0 3.683-3.014 6.697-6.697 6.697-3.684 0-6.698-3.014-6.698-6.697 0-3.684 3.014-6.698 6.698-6.698 3.683 0 6.697 3.014 6.697 6.698Z"
                    />
                  </svg>
                }
                UNSAFE_className="px-1 [&_svg]:w-4 [&_svg]:h-4"
              />
            </Stack>
          </TextField.Slot>

          <div
            className={styles({
              isOpen: autocompleteState.isOpen,
            }).panel()}
            aria-labelledby={AUTOCOMPLETE_LABEL}
            data-state={autocompleteState.isOpen ? 'open' : 'closed'}
            ref={panelRef}
            {...autocompleteProps.panel}
          >
            {autocompleteState.isOpen && (
              <ul
                className={styles().grid({
                  areas: hasRecentSearchQuery,
                })}
              >
                {autocompleteState.collections.map((item) => {
                  if (
                    item.source.sourceId === 'recentSearchesPlugin' &&
                    hasRecentSearchQuery
                  ) {
                    return (
                      <RecentsSearch
                        key={item.source.sourceId}
                        refine={refine}
                        {...item}
                        {...autocomplete}
                        {...recents}
                      />
                    );
                  }

                  if (item.source.sourceId === 'querySuggestionsPlugin') {
                    return (
                      //@ts-ignore
                      <QuerySuggestions
                        key={item.source.sourceId}
                        query={autocompleteState.query}
                        refine={refine}
                        {...item}
                        {...autocomplete}
                      />
                    );
                  }

                  if (item.source.sourceId === 'products') {
                    return (
                      <ProductList
                        key={item.source.sourceId}
                        {...item}
                        {...autocomplete}
                      />
                    );
                  }

                  return null;
                })}
              </ul>
            )}
          </div>
        </TextField.Root>
      </form>
    </div>
  );
}

export default React.memo(Searchbar);
